



























import { Vue, Prop, Component } from 'vue-property-decorator';

@Component
export default class ConfirmDeletion extends Vue {
  @Prop(String) readonly deleteKeyword: string;


  public deleteValue = false;

  get isDeleteDisabled (): boolean {
      return this.deleteValue === false;
  }

  public confirmDelete (): void {
      this.$emit('confirmDelete');
  }
}
