var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "confirm-delete-wrapper" }, [
    _c("div", { staticClass: "header" }, [
      _c("h3", [_vm._v("Delete " + _vm._s(_vm.deleteKeyword))]),
    ]),
    _c(
      "div",
      { staticClass: "body" },
      [
        _vm._t("default"),
        _c("p", [
          _vm._v("Please confirm that you wish to delete "),
          _c("span", { staticClass: "text-danger" }, [
            _vm._v(_vm._s(_vm.deleteKeyword)),
          ]),
        ]),
        _c("div", { staticClass: "form-control" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.deleteValue,
                expression: "deleteValue",
              },
            ],
            attrs: { type: "checkbox", id: "deleteCheckbox" },
            domProps: {
              checked: Array.isArray(_vm.deleteValue)
                ? _vm._i(_vm.deleteValue, null) > -1
                : _vm.deleteValue,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.deleteValue,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.deleteValue = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.deleteValue = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.deleteValue = $$c
                }
              },
            },
          }),
          _c(
            "label",
            { attrs: { for: "deleteCheckbox" } },
            [
              _vm._v(" Delete "),
              _c("font-awesome-icon", {
                class: { "text-danger": _vm.deleteValue },
                attrs: { icon: ["fal", "trash-alt"] },
              }),
            ],
            1
          ),
        ]),
      ],
      2
    ),
    _c("div", { staticClass: "footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn--default discard",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.$emit("discardDeleteModal")
            },
          },
        },
        [_vm._v("Cancel")]
      ),
      _c(
        "button",
        {
          staticClass: "btn btn--default",
          class: { disabled: _vm.isDeleteDisabled },
          attrs: { type: "button", disabled: _vm.isDeleteDisabled },
          on: { click: _vm.confirmDelete },
        },
        [_vm._v(" Delete ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }